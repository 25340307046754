import useSWR from "swr";

export interface Customer {
  id: string;
  name: string;
  nyceId: string;
  clusterId: string;
  cutOffTime: string;
  availableDates: {
    dateType: string;
    firstAvailableDate: string;
    lastAvailableDate: string;
  }[];
  forecast: {
    orderType: string;
    enabled: boolean;
    deadline: string;
  }[];
}

export const useCustomers = () => useSWR<Customer[]>("/customers");
