import { useMsal } from "@azure/msal-react";
import env from "config/env";
import { useCustomers } from "hooks/use-customers";
import { getDefaultPathForPermissions, useUser } from "hooks/use-user";
import { ZENDESK_STORAGE_KEY } from "hooks/use-zendesk";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const REDIRECT_STORAGE_KEY = "redirect-url";
export const REDIRECT_TIMESTAMP_STORAGE_KEY = "redirect-timestamp";

function readUrlParam(url: string, param: string) {
  param += "=";
  if (url.indexOf(param) !== -1) {
    let start = url.indexOf(param) + param.length;
    let value = url.substr(start);
    if (value.indexOf("&") !== -1) {
      const end = value.indexOf("&");
      value = value.substring(0, end);
    }
    return value;
  }

  return false;
}

const storeZendeskAccessToken = () => {
  const url = window.location.href;
  if (url.indexOf(env.redirectUri) !== -1) {
    if (url.indexOf("access_token=") !== -1) {
      const access_token = readUrlParam(url, "access_token");
      if (access_token) localStorage.setItem(ZENDESK_STORAGE_KEY, access_token);
    }
  }
};

const isMoreThan8HoursAgo = (timestampStr: string) => {
  const timestamp = Number.parseInt(timestampStr);
  const now = Date.now();
  const eightHoursAgo = now - 8 * 60 * 60 * 1000;
  return timestamp < eightHoursAgo;
};

export const HomeRedirect = () => {
  const router = useRouter();
  const { accounts } = useMsal();
  const { data: customers } = useCustomers();
  const { user, permissionsBySection } = useUser();

  const account = accounts?.[0];

  useEffect(() => {
    if (user && customers && customers?.length > 0 && account?.localAccountId) {
      const firstCustomer = customers[0];
      const defaultPath = getDefaultPathForPermissions(permissionsBySection);
      const storedRedirectUrl =
        window.localStorage.getItem(REDIRECT_STORAGE_KEY);
      const storedTimestamp = window.localStorage.getItem(
        REDIRECT_TIMESTAMP_STORAGE_KEY,
      );

      const isExpired = storedTimestamp && isMoreThan8HoursAgo(storedTimestamp);

      storeZendeskAccessToken();

      const target =
        storedRedirectUrl && !isExpired
          ? storedRedirectUrl
          : defaultPath
            ? `/${firstCustomer.id}${defaultPath}`
            : "/401";

      router.replace(target);
    }
  }, [user, customers, permissionsBySection, router, account]);

  return null;
};
