import nProgress from "nprogress";
import { useEffect } from "react";
import styles from "./index.module.css";

export const PageLoading = () => {
  useEffect(() => {
    nProgress.start();
    return () => {
      nProgress.done();
    };
  });

  return (
    <div className={styles.Container}>
      <img src="/images/logo.png" alt="Bonver" width={145} height={39} />
    </div>
  );
};
