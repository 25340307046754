import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { HomeRedirect } from "components/shared/home-redirect";
import { PageLoading } from "components/shared/page-loading";
import { loginRequest } from "config/auth";
import { useUpdateToken } from "hooks/use-update-token";
import type { NextPage } from "next";
import { useEffect } from "react";

export interface HomeProps {
  customers: { id: string; name: string }[];
}

const Home: NextPage<HomeProps> = ({}: HomeProps) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const isAccessTokenSet = useUpdateToken();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <>
      <PageLoading />
      {isAuthenticated && isAccessTokenSet && <HomeRedirect />}
    </>
  );
};

export default Home;
